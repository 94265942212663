import React from "react";

import { BASE_URL } from "../../utils/axios/axios";

const SubDestination = ({ data }) => {
  // console.log(data);
  return (
    <div className="w-[85vw] mx-auto pt-14">
      <div>
        <h1 className="md:text-5xl text-4xl text-[#1D306D] pb-6">
          {data.name}
        </h1>
        <p className="md:text-lg text-[#8A94B2] pb-6 text-justify">
          {data.description}
        </p>
      </div>

      <a
        href={`/destination/${data.id}`}
        className="bg-secondary text-white text-sm p-4 rounded-full font-bold"
      >
        Learn More !
      </a>

      <div className="my-10 mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6">
        {data.image1 && (
          <div className="w-full h-[35vh]">
            <img
              src={`${BASE_URL}/${data.image1.filePath}`}
              alt={data.image1.altinfo}
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        )}
        {data.image2 && (
          <div className="w-full h-[35vh]">
            <img
              src={`${BASE_URL}/${data.image2.filePath}`}
              alt={data.image2.altinfo}
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        )}
        {data.image3 && (
          <div className="w-full h-[35vh]">
            <img
              src={`${BASE_URL}/${data.image3.filePath}`}
              alt={data.image3.altinfo}
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        )}
        {data.image4 && (
          <div className="w-full h-[35vh]">
            <img
              src={`${BASE_URL}/${data.image4.filePath}`}
              alt={data.image4.altinfo}
              className="rounded-3xl w-full h-full object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubDestination;
