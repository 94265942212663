import React from "react";
import { Helmet } from "react-helmet";

import Blogs from "../components/sustainability/blogs";
import SustainabilityLanding from "../components/sustainability/sustainabilityLanding";
import TravelPower from "../components/sustainability/travelPower";

const sustainability = () => {
  return (
    <div className="bg-[#f7f7f7] scroll-smooth pb-10">
      <Helmet>
        <title>Sustainable Travel Blogs</title>
        <meta
          name="description"
          content="At Druk Air Holidays, we prioritize sustainable travel. Learn how we support eco-friendly tourism in Bhutan and promote responsible travel. Stay updated with the latest travel tips, news, and stories about Bhutan on the Druk Air Holidays blog."
        />
        <meta
          name="keywords"
          content="sustainable travel Bhutan, eco-friendly tourism Bhutan, Druk Air Holidays sustainability, responsible travel Bhutan, green tourism, Bhutan travel blog, Druk Air Holidays blog, Bhutan travel tips, travel stories Bhutan"
        />
      </Helmet>
      <SustainabilityLanding />
      <TravelPower />
      <Blogs />
    </div>
  );
};

export default sustainability;
