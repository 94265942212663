import { useEffect, useState } from "react";

import { axios } from "./axios";

const useAxiosAuth = (url) => {
  // const authHeader = useAuthHeader();

  // const interceptorRef = useRef(null);

  // useEffect(() => {
  // 	interceptorRef.current = axios.interceptors.request.use(
  // 		(config) => {
  // 			if (!config.headers["Authorization"]) {
  // 				config.headers["Authorization"] = authHeader;
  // 			}
  // 			return config;
  // 		},
  // 		(error) => Promise.reject(error)
  // 	);

  // 	return () => {
  // 		axios.interceptors.request.eject(interceptorRef.current);
  // 	};
  // }, [authHeader]);

  //   return axios;
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (url) {
      axios
        .get(`/api/${url}`)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [url]);
  return { data, error };
};

export default useAxiosAuth;
