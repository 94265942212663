import { useParams } from "react-router-dom";

import specialSVG from "../assets/images/specialSvg.svg";
import { BASE_URL } from "../utils/axios/axios";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Content = () => {
  const { contentId } = useParams();

  const { data: content } = useAxiosAuth(`content/${contentId}`);

  return (
    <>
      <div className="bg-cover">
        <div className="content-image-overlay">
          {content?.image ? (
            <img
              src={`${BASE_URL}/${content.image.filePath}`}
              alt="Sustainability Background"
              className="absolute -top-[1px] h-[60vh] w-full object-cover "
            />
          ) : (
            <img
              src={require("../assets/images/sustainability1.jpg")}
              alt="Sustainability Background"
              className="absolute -top-[1px] h-[60vh] w-full object-cover "
            />
          )}
          <div className="overlay h-[60vh] top-0"></div>
        </div>
        <div className="relative -top-20 h-[61vh]">
          <img
            className="absolute bottom-0 left-0 w-full"
            src={specialSVG}
            alt="Special SVG"
          />

          <div className="grid grid-cols-1 content-center h-full 2xl:w-[90vw] lg:px-10 px-5 lg:pt-0 pt-20 mx-auto text-white ">
            <h1 className="text-4xl font-bold text-white">{content.name}</h1>
            <h2 className="text-lg pt-2 pb-8 text-white">
              Druk Air Holidays - {content.name}
            </h2>
          </div>
        </div>
      </div>
      <div className="2xl:w-[90vw] lg:px-10 px-5 mx-auto pb-12 ">
        <div
          className="content-data"
          dangerouslySetInnerHTML={{
            __html: content.description,
          }}
        ></div>
      </div>
    </>
  );
};

export default Content;
