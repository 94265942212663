import React, { useContext, useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";

import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function Menu() {
  const { closeMenu } = useContext(MenuContext);

  const { data: destination } = useAxiosAuth(`destination`);

  const [destinationDrop, setDestinationDrop] = useState(false);
  // NAV update here
  const navItems = [
    // { text: "Home Page", url: "/" },
    { text: "Packages", url: "/packages" },
    // { text: "Destinations", url: "/destination" },
    { text: "Tailor Made Trip", url: "/tailormadetrip" },
    { text: "Flight", url: "/flight" },
    { text: "Group Departure", url: "/groupdeparture" },
    { text: "Sustainability", url: "/sustainability" },
    { text: "Contact Us", url: "/contact" },
  ];

  return (
    <div className="h-screen overflow-y-auto">
      <div className="xl:w-[90vw] px-5 mx-auto relative flex items-center justify-between h-20">
        <div className="flex-none items-center">
          <a href="/">
            <img
              className="h-10 2xl:w-full xl:w-56 lg:w-40 w-52 object-contain transition-all ease-in-out duration-150"
              src={require("../../assets/images/DrukairLogo.png")}
              alt="Logo"
            />
          </a>
        </div>
        <button
          onClick={closeMenu}
          className="text-2xl flex-1 flex justify-end"
        >
          <RxCross1 />
        </button>
      </div>

      <div className="flex flex-col space-y-2 w-[80vw] mx-auto pt-6">
        {/* before destination nav */}
        {navItems.slice(0, 2).map((item, index) => (
          <div key={index}>
            <a
              href={item.url}
              className="cursor-pointer text-[30px] pb-5 block"
            >
              {item.text}
            </a>
          </div>
        ))}
        {/* Destination */}
        <div className="relative">
          <div
            className="cursor-pointer pb-5 text-[30px]"
            onClick={() => setDestinationDrop(!destinationDrop)}
          >
            Destination&nbsp;
            <IoIosArrowDown
              className={`${!destinationDrop ? "" : "rotate-180"} transition ease-in-out inline`}
            />
          </div>
          {destinationDrop && (
            <div className=" top-12 pb-5 rounded-lg overflow-hidden z-20">
              {destination.map((item, index) => (
                <a
                  className="block border-y hover:bg-slate-300 px-5 py-2"
                  key={index}
                  href={`/destination/${item.id}`}
                >
                  {item.name}
                </a>
              ))}
            </div>
          )}
        </div>
        {/* after destination nav */}
        {navItems.slice(2).map((item, index) => (
          <div key={index}>
            <a
              href={item.url}
              className="cursor-pointer text-[30px] pb-5 block"
            >
              {item.text}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Menu;
