import React from "react";
import { Helmet } from "react-helmet";

import Memorable from "../../components/tourPackage/memorable";
import TourPackageCarousel from "../../components/tourPackage/tourPackageCarousel";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import Landing from "./landing";

const TourPackages = () => {
  const { data: packages } = useAxiosAuth(`Package`);

  return (
    // Landing of Tour Package
    <div className="w-dvw">
      <Helmet>
        <title>Tour Packages</title>
        <meta
          name="description"
          content="Discover our range of Bhutan tour packages at Druk Air Holidays. Choose from cultural, adventure, and spiritual tours tailored to your interests."
        />
        <meta
          name="keywords"
          content="Bhutan tour packages, Druk Air Holidays tours, travel packages Bhutan, cultural tours Bhutan, adventure tours Bhutan, Bhutan trip packages."
        />
      </Helmet>
      <Landing />

      {/* Components */}
      {packages.length > 0 && <TourPackageCarousel packages={packages} />}

      <Memorable />
    </div>
  );
};

export default TourPackages;
