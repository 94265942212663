import React from "react";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import specialSVG from "../../assets/images/specialSvg.svg";
import "../../styles/styles.css";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[40vh] md:top-[44vh] lg:top-[48vh] md:left-[6vw] left-[13vw]  z-[1] border text-4xl text-white  rounded-3xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[40vh] md:top-[44vh] lg:top-[48vh]  md:left-0  z-[1] border  text-4xl text-white  rounded-3xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}

const Special = () => {
  const { data: packages } = useAxiosAuth("package");

  const settings = {
    autoplay: true,
    // autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const pubPackages = packages.filter((packages) => packages.published);
  return (
    <div className="h-screen bg-cover">
      <div className="sus-image-overlay">
        <img
          src={require("../../assets/images/landing.jpg")}
          alt="Home landing bg"
          className="absolute -top-[1px] h-full w-full object-cover"
        />
        <div className="overlay"></div>
      </div>

      <img
        className="-bottom-[0.25rem] left-0 absolute w-screen"
        src={specialSVG}
        alt="/"
      />

      <div className="lg:grid grid-cols-2 gap-4 xl:w-[90vw] px-5 mx-auto content-center h-screen relative -top-10">
        <div className="text-white lg:w-[43vw] md:w-[80vw] relative">
          <div className="border-t-8 w-16 pb-3 border-white relative"></div>
          <h1 className="lg:text-3xl text-xl mb-2 lg:mb-4">Bhutan: Believe</h1>
          <p className="2xl:text-9xl lg:text-7xl md:text-6xl text-4xl mb-4 lg:mb-6">
            Special Packages
          </p>
          <p className="lg:text-lg mb-8 xl:w-[70%]">
            Drukair Holidays (the holiday wing of Drukair - Royal Bhutan
            Airlines) offers luxury vacations, private tours and small group
            adventures with the unique experience in Bhutan.
          </p>
          <a
            href="./packages"
            className="bg-secondary text-white text-sm p-4 rounded-full"
          >
            View All Packages
          </a>
        </div>

        <div className="specialSlide ">
          <Slider {...settings}>
            {pubPackages.map((packageIndividual, index) => (
              <div key={index} className="block rounded-2xl">
                <a
                  href={`./package/${packageIndividual.slug}`}
                  className="relative"
                >
                  {packageIndividual.images && (
                    <>
                      {packageIndividual.image1 ? (
                        <div className="image-overlay">
                          <img
                            className="w-[45vw] sm:w-[33vw] h-[40vh] object-cover rounded-xl"
                            src={`${BASE_URL}/${packageIndividual.image1.filePath}`}
                            alt={packageIndividual.image1.altinfo}
                          />
                          <div className="overlay"></div>
                        </div>
                      ) : (
                        <div className="image-overlay">
                          <img
                            className="w-[45vw] sm:w-[33vw] h-[40vh] object-contain px-10 bg-white rounded-xl"
                            src={require("../../assets/images/DrukairLogo.png")}
                            alt={"thumbnail unavailable"}
                          />
                          <div className="overlay"></div>
                        </div>
                      )}
                    </>
                  )}

                  <span className="absolute left-0 bottom-0 px-4 py-4 text-[#ffffff]">
                    {packageIndividual.title}
                    <br />
                    <span className="flex items-center">
                      <IoCalendarNumberOutline className="md:text-md mr-2" />
                      {packageIndividual.tripDays} Days /{" "}
                      {packageIndividual.tripNights}{" "}
                      {packageIndividual.tripNights === 1 ? "Night" : "Nights"}
                    </span>
                  </span>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Special;
