import React from "react";
import { Helmet } from "react-helmet";

import Banner from "../components/home/banner";
import Choose from "../components/home/choose";
import Inspiration from "../components/home/inspiration";
import Partners from "../components/home/partners";
import Serving from "../components/home/serving";
import Special from "../components/home/special";
import SupportedBy from "../components/home/supportedBy";

const Home = () => {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Druk Air Holidays - Travel to Bhutan and Beyond</title>
        <meta
          name="description"
          content="Drukair Holidays (from Drukair - Royal Bhutan Airlines) offers luxury vacations, private tours and small group adventures with the unique experience in Bhutan."
        />
        <meta
          name="keywords"
          content="Druk air holidays, druk air, druk, royal bhutan airlines, Bhutan, Singapore, Bhutan travel packages, Best time to visit Bhutan, Bhutan tour guide, Bhutan cultural tours, Paro and Thimphu travel, Trekking in Bhutan, Bhutan holiday destinations, Bhutan adventure tours, Travel to Bhutan, Bhutan travel permits and visa"
        />
      </Helmet>
      <Special />
      <Choose />
      <Serving />
      <Inspiration />
      <Banner />
      <Partners />
      <SupportedBy />
    </div>
  );
};

export default Home;
