import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { FaRegStar, FaStar } from "react-icons/fa";

import { BASE_URL } from "../../utils/axios/axios";

const Hotels = ({ packageInformation }) => {
  const getStars = (item) => {
    const starsInit = [];
    for (let i = 0; i < item; i++) {
      starsInit.push(<FaStar key={i} className="text-[#F2994A] text-lg" />);
    }
    for (let i = 0; i < 5 - item; i++) {
      starsInit.push(<FaRegStar key={i} className="text-[#F2994A] text-lg" />);
    }
    return starsInit;
  };

  return (
    <div className="bg-[#FFFFFF] md:w-[70vw] w-[90vw] md:mt-0 mt-8 p-6 border  rounded-lg shadow md:mb-0 mb-8">
      <h1 className="pb-4">Amazing Hotels at Location</h1>

      <div className=" block space-y-6">
        {packageInformation.hotels &&
          packageInformation.hotels.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center border bg-[#FFFFFF] border-[#57585C]  rounded-2xl md:flex-row"
            >
              <img
                className="object-cover p-4 w-80 rounded-3xl"
                src={
                  item.image
                    ? `${BASE_URL}/${item.image.filePath}`
                    : "https://placehold.co/600x400.png"
                }
                alt=""
              />
              <div className="flex flex-col p-4 ">
                <div className="flex items-center leading-8">
                  <div>
                    <h1 className="text-2xl font-bold">{item.name}</h1>
                    <div className="flex items-center">
                      <div className="flex gap-0.5">{getStars(4)}</div>
                      <p className="text-gray-400">
                        &nbsp; 4 (1200 Reviews) &nbsp;&nbsp;
                      </p>
                    </div>
                    <p className="flex items-center pb-2 text-gray-500">
                      <div className="text-2xl text-secondary">
                        <CiLocationOn />
                      </div>
                      &nbsp;
                      <div className="text-sm">{item.location}</div>
                    </p>
                  </div>
                </div>
                <p className="text-gray-500 text-sm">{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Hotels;
