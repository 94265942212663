import React from "react";

import specialSVG from "../../assets/images/specialSvg.svg";

const SustainabilityLanding = () => {
  const handleClick = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetSection = document.getElementById(targetId);

    window.scrollTo({
      top: targetSection.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-cover">
      <div className="sus-image-overlay">
        <img
          src={require("../../assets/images/sustainability1.jpg")}
          alt="Sustainability Background"
          className="absolute -top-[1px] h-full w-full object-cover "
        />
        <div className="overlay"></div>
      </div>
      <div className="relative h-[93vh]">
        <img
          className="absolute bottom-0 left-0 w-full"
          src={specialSVG}
          alt="Special SVG"
        />

        <div className="absolute flex flex-col left-[6vw] top-[28vh] sm:left-[10vw] sm:top-[30vh] md:left-[8vw] md:top-[25vh] lg:left-[6vw] lg:top-[28vh] p-4">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white">
            Sustainable Travel
          </h1>
          <p className="w-[50vw] sm:w-[40vw] md:w-[45vw] lg:w-[34vw] my-4 text-[14px] sm:text-[16px] md:text-[18px] text-white text-justify">
            We fully believe in travel as a force for good: Sustainable travel
            preserves the culture, protects nature, as well as supports the
            local economy. Our travel specialist could assist you in making a
            positive impact whenever and wherever you travel
          </p>
          <a
            href="#travelPower"
            className="w-32 bg-secondary text-white text-center rounded-full py-2 sm:py-3 md:py-4 "
            onClick={handleClick}
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityLanding;
