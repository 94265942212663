import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Blogs = () => {
  const { data: blogs } = useAxiosAuth("blogpost");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="xl:w-[90vw] px-5 mx-auto " id="blogs">
      <h1 className="text-3xl md:text-4xl lg:text-6xl text-primary">Blogs</h1>
      <p className="my-4 text-[14px] md:text-[16px] lg:text-[18px] text-[#959595] text-justify">
        Discover inspiration for your next meaningful adventure through these
        stories, sustainable travel ideas, and tips.
      </p>
      {blogs && (
        <div className="grid sm:grid-cols-2 md:grid-cols-1 gap-4">
          {blogs.map((blog, index) => (
            <Link
              className="flex flex-col md:flex-row gap-8 bg-[#ffffff] rounded-2xl mt-4 p-4 md:p-8 hover:scale-[1.01] transition ease-in-out border"
              to={`/sustainability/${blog.id}`}
              key={index}
            >
              <img
                src={`${BASE_URL}/${blog.thumbnail.filePath}`}
                alt={blog.title}
                className="rounded-xl w-full md:min-w-[20vw] md:w-[20vw] h-[15vw] object-cover"
              />
              <div className="mt-4 md:mt-0">
                <h1 className="text-[18px] md:text-[20px] lg:text-[24px] text-[#000000] font-bold">
                  {blog.title}
                </h1>
                <span className="mt-2 md:mt-4 text-secondary">
                  <FaRegUser className="text-secondary inline" /> {blog.author}
                </span>
                <p className="my-4 text-[14px] md:text-[16px] lg:text-[18px] text-[#959595] text-justify">
                  {parse(
                    isLargeScreen
                      ? blog.content.substring(0, 600)
                      : blog.content.substring(0, 160)
                  )}
                </p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blogs;
