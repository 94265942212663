// routes
// styles
import "./App.css";
import "./styles/general.css";
import "./styles/styles.css";
import AppRouting from "./utils/routing";

function App() {
  return <AppRouting />;
}

export default App;
