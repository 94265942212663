import React from "react";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlinePhone } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "../../styles/styles.css";
import { BASE_URL } from "../../utils/axios/axios";
// import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute xl:top-[-15vh] top-[-12vh] 2xl:right-14 right-0 border-2 p-2 bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute xl:top-[-15vh] top-[-12vh] 2xl:right-[7vw] lg:right-[8vw] md:right-[8vw] right-[15vw] border-2 p-2 border-secondary text-4xl text-secondary rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}

const ContactCarousel = () => {
  const { data: contact } = useAxiosAuth(`OfficeLocation`);
  // const filteredContact = contact.filter((item) =>
  //   item.identifier.includes("chooseUs")
  // );
  // console.log(contact);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1315,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className=" w-[85vw] mx-auto relative">
      <h1 className="text-5xl font-bold text-[#1D306D] pb-2">Contact Us</h1>
      <p className="text-[#8A94B2]">Reach out to us in any of our branches!</p>

      <div className="contactCarousel md:pt-20 pt-40">
        {contact.length > 0 && (
          <Slider {...settings}>
            {contact.map((item, index) => (
              <div
                key={index}
                className="max-w-[90%] p-8 border border-gray-200 rounded-lg shadow-xl"
              >
                <div className="mx-auto flex translate-x-28 -translate-y-10 items-center justify-center ">
                  <img
                    className="object-contain absolute w-40 border right-[15vw] sm:right-[10vw] md:right-[5vw]"
                    src={`${BASE_URL}/${item.vault.filePath}`}
                    alt={item.vault.altInfo}
                  />
                </div>
                <h5 className="mb-2 text-4xl font-bold text-[#517C9F]">
                  {item.country}
                </h5>
                <p className="text-[#A0A0A0] md:w-[80%] pb-4">
                  {item.corporateOffice}
                </p>
                <div className="space-y-2 text-sm">
                  <a
                    href={`tel:${item.phone}`}
                    className="flex gap-2 items-center hover:font-medium hover:text-[#517C9F]"
                  >
                    <MdOutlinePhone /> {item.phone}
                  </a>
                  <a
                    href={`mailto:${item.email}`}
                    className="flex gap-2 items-center hover:font-medium hover:text-[#517C9F]"
                  >
                    <CiMail />
                    {item.email}
                  </a>
                  <a
                    href={`${item.locationUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex gap-2 items-center hover:font-medium hover:text-[#517C9F]"
                  >
                    <CiLocationOn />
                    {item.address}
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ContactCarousel;
