import { Accordion } from "flowbite-react";
import React from "react";
import { Helmet } from "react-helmet";

import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Faq = () => {
  const { data: content } = useAxiosAuth(`faq`);
  console.log(content);
  return (
    <div className="xl:w-[90vw] px-5 mx-auto pb-10">
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta
          name="description"
          content="Find answers to common questions about traveling to Bhutan with Druk Air Holidays, including visa requirements, itineraries, and booking information."
        />
        <meta
          name="keywords"
          content="Bhutan travel FAQs, Druk Air Holidays questions, Bhutan trip answers, travel tips Bhutan, booking information Bhutan"
        />
      </Helmet>
      <div className="text-left pt-10">
        <h1 className="text-4xl font-bold text-primary capitalize">
          frequently asked questions
        </h1>
        <h2 className="text-lg pt-2 pb-8 text-secondary">
          Get answers to common questions
        </h2>
      </div>
      <div className="FAQ">
        <Accordion>
          {content.map((item, index) => (
            <Accordion.Panel key={index}>
              <Accordion.Title className="text-primary bg-gray-50">
                {item.question}
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">{item.answer}</p>
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
