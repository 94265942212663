import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import { useState } from "react";

import Hotels from "./hotels";
import IncludesExcludes from "./includesExcludes";
import Itinerary from "./itinerary";
import Overview from "./overview";
import PackageLayout from "./packageLayout";

const PackageNav = ({ packageInformation, group }) => {
  const [value, setValue] = useState(0);
  return (
    <div className="pt-16">
      <Paper square>
        <Tabs
          value={value}
          textColor="primary"
          indicatorColor="primary"
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className="w-[90vw] mx-auto"
        >
          <Tab label="Overview" />
          <Tab label="Itinerary Details" />
          <Tab label="Hotels" />
          <Tab label="Inclusions/Exclusions" />
        </Tabs>
        {value === 0 ? (
          <PackageLayout packageInformation={packageInformation} group={group}>
            <Overview packageInformation={packageInformation} />
          </PackageLayout>
        ) : value === 1 ? (
          <PackageLayout packageInformation={packageInformation} group={group}>
            <Itinerary packageInformation={packageInformation} />
          </PackageLayout>
        ) : value === 2 ? (
          <PackageLayout packageInformation={packageInformation} group={group}>
            <Hotels packageInformation={packageInformation} />
          </PackageLayout>
        ) : value === 3 ? (
          <PackageLayout packageInformation={packageInformation} group={group}>
            <IncludesExcludes packageInformation={packageInformation} />
          </PackageLayout>
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
};

export default PackageNav;
