import React, { useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute md:top-[-20vh] top-[-12vh] 2xl:right-0 right-[5vw] border-2 p-2 bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute md:top-[-20vh] top-[-12vh] 2xl:right-[7vw] md:right-[10vw] right-[25vw] border-2 p-2 border-secondary text-4xl text-secondary rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}

const TruncatedText = ({ text, charLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const shouldShowButton = text.length > charLimit;
  const displayText = isExpanded ? text : text.slice(0, charLimit) + "...";

  return (
    <div>
      <p className="text-gray-500 inline">
        {shouldShowButton ? displayText : text}&nbsp;
      </p>
      {shouldShowButton && (
        <div
          onClick={handleToggle}
          className="text-blue-900 hover:text-blue-600 inline cursor-pointer"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </div>
      )}
    </div>
  );
};

const Inspiration = () => {
  // data pull and filter
  const { data: testimonial } = useAxiosAuth(`testimonial`);
  const filteredTestimonial = testimonial.filter((item) => item.published);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getStars = (item) => {
    const starsInit = [];
    for (let i = 0; i < item; i++) {
      starsInit.push(<FaStar className="text-secondary text-lg" />);
    }
    for (let i = 0; i < 5 - item; i++) {
      starsInit.push(<FaRegStar className="text-secondary text-lg" />);
    }
    return starsInit;
  };

  return (
    <div>
      <div className="xl:w-[90vw] px-5 mx-auto text-[#1D306D] md:pb-0 pb-6">
        <h1 className="md:text-5xl text-3xl pb-3">
          Our Inspiration, Your Moment
        </h1>
        <p className="text-lg text-[#8A94B2]">
          What Happy Guests Say About Our Services?
        </p>
      </div>

      <div className="chooseTry pt-28 p-4 mx-auto gap-9 testimonial max-w-screen-2xl">
        {filteredTestimonial.length > 0 && (
          <Slider {...settings}>
            {filteredTestimonial.map((item, index) => (
              <div
                key={index}
                className="rounded-xl p-6 text-center shadow-lg max-w-[90%] mx-auto inspiration-block bg-wwhite"
              >
                <div className="h-10">
                  <div className="mx-auto flex h-16 w-16 -translate-y-12 items-center justify-center rounded-full bg-wwhite shadow-sm shadow-gray-200">
                    <img
                      className="rounded-full object-cover bg-contain h-16 w-16 "
                      src={`${BASE_URL}/${item.image.filePath}`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full gap-5">
                  <TruncatedText text={item.description} charLimit={120} />
                  <div className="flex w-full justify-between text-left">
                    <div className="flex gap-0.5">{getStars(item.rating)}</div>
                    <div>
                      <h1 className="text-xl">{item.name}</h1>
                      <p className="text-[#8A94B2]">{item.location}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default Inspiration;
