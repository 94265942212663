import React from "react";

const Landing = () => {
  const handleClick = (event) => {
    // event.preventDefault();
    // const targetId = event.currentTarget.getAttribute("href").substring(1);
    // const targetSection = document.getElementById(targetId);
    // window.scrollTo({
    //   top: targetSection.offsetTop,
    //   behavior: "smooth",
    // });
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="sm:h-[90vh] xl:w-[90vw] px-5 mx-auto grid md:grid-cols-[45%_55%] gap-6 relative">
      <div className="h-[60%] my-auto">
        <div className="bg-secondary w-[10%] h-2"></div>
        <br />
        <span className="text-secondary text-[20px] xl:text-3xl">
          Find What Suits You Best!
        </span>
        <br />
        <span className="text-[#4B4F52] xl:text-[60px] text-[40px] sm:w-full">
          Travel As Far as your heart takes you
        </span>
        <br />
        <br />
        <br />
        <button
          onClick={handleClick}
          className="bg-secondary text-white rounded-full p-4"
        >
          See Packages
        </button>
        <div className="relative w-[90%] h-[200px] sm:w-[80%] sm:h-[180px] lg:h-[250px] my-10">
          <img
            src={require("../../assets/images/tourPlane.png")}
            alt=""
            className="absolute left-0 w-full h-[50%] object-contain"
            style={{ transform: "translateX(-20%)" }}
          />
        </div>
      </div>

      <div className=" grid-cols-2 gap-10 h-[70%] lg:h-[90%] my-auto grid pr-10">
        <div className="w-full h-[75%]">
          <img
            src={require("../../assets/images/tourPackage1.png")}
            alt=""
            className="w-full h-full rounded-[40px] object-cover"
          />
        </div>
        <div className="w-full h-[75%] md:self-end">
          <img
            src={require("../../assets/images/tourPackage2.png")}
            alt=""
            className="w-full h-full rounded-[40px] object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
