import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { IoIosArrowDown } from "react-icons/io";
import { IoMenuOutline } from "react-icons/io5";

import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Navbar = () => {
  const { toggleMenu } = useContext(MenuContext);

  const { data: destination } = useAxiosAuth(`destination`);

  const [activeLink, setActiveLink] = useState(null);
  const [destinationDrop, setDestinationDrop] = useState(false);
  const [navBackground, setNavBackground] = useState(false);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // NAV update here
  const links = [
    // { text: "Home Page", url: "/" },
    { text: "Packages", url: "/packages" },
    // { text: "Destinations", url: "/destination" },
    { text: "Tailor Made Trip", url: "/tailormadetrip" },
    { text: "Flight", url: "/flight" },
    { text: "Group Departure", url: "/groupdeparture" },
    { text: "Sustainability", url: "/sustainability" },
    { text: "Contact Us", url: "/contact" },
  ];
  return (
    <nav
      className={`fixed w-full z-[20] transition-all ease-in-out duration-150 backdrop-blur-md ${
        navBackground
          ? "bg-white bg-opacity-75 shadow-lg shadow-[#0c153813]"
          : "bg-black bg-opacity-50"
      }`}
    >
      <div className="xl:w-[90vw] px-5 mx-auto relative flex items-center justify-between h-20">
        <div className="flex-none items-center">
          <a href="/">
            <img
              className="h-10 2xl:w-full xl:w-56 lg:w-40 w-52 object-contain transition-all ease-in-out duration-150"
              src={require(
                navBackground
                  ? "../../assets/images/DrukairLogo.png"
                  : "../../assets/images/DrukairLogoWhite.png"
              )}
              alt="Logo"
            />
          </a>
        </div>
        <div className="flex-1 flex justify-end">
          <div className="sm:ml-6 hidden lg:block ">
            <div
              className={`flex xl:space-x-10 space-x-6 transition-all ease-in-out duration-150 ${
                navBackground ? "text-primary" : "text-white"
              }`}
            >
              {/* before destination nav */}
              {links.slice(0, 2).map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  onClick={() => handleLinkClick(index)}
                  // className={`${
                  //   activeLink === index && "border-b-2 border-white pb-4"
                  // }`}
                >
                  {link.text}
                </a>
              ))}

              {/* Destination */}
              <div className="relative">
                <div
                  className="cursor-pointer"
                  onClick={() => setDestinationDrop(!destinationDrop)}
                >
                  Destination&nbsp;
                  <IoIosArrowDown
                    className={`${!destinationDrop ? "" : "rotate-180"} transition ease-in-out delay-150 inline`}
                  />
                </div>
                {destinationDrop && (
                  <div
                    className={`absolute top-12 shadow-lg rounded-lg overflow-hidden z-20 ${
                      navBackground ? "border-2" : ""
                    }`}
                  >
                    {destination.map((item, index) => (
                      <a
                        className={`block px-5 py-2 ${
                          navBackground
                            ? "bg-white hover:bg-slate-200 text-primary"
                            : "backdrop-blur-md bg-primary bg-opacity-70 hover:bg-slate-500 text-white"
                        }`}
                        key={index}
                        href={`/destination/${item.id}`}
                        onClick={() => handleLinkClick(index)}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>

              {/* after destination nav */}
              {links.slice(2).map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  onClick={() => handleLinkClick(index)}
                  // className={`${
                  //   activeLink === index && "border-b-2 border-white pb-4"
                  // }`}
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>
        </div>
        <button
          onClick={toggleMenu}
          className={`text-4xl block lg:hidden ${
            navBackground ? "text-primary" : "text-white"
          }`}
        >
          <IoMenuOutline />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
