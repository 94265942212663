import React, { useState } from "react";
import parse from 'html-react-parser';

const Itinerary = ({ packageInformation }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="block space-y-6 md:w-[70vw] w-[90vw] p-6 border bg-[#FFFFFF] rounded-xl md:mt-0 mt-8 md:mb-0 mb-8">
      <h5 className="mb-2 ">Itinerary</h5>
      {packageInformation.itineraryDetails &&
        packageInformation.itineraryDetails
          .sort((a, b) => a.day - b.day)
          .map((item, index) => (
            <div className="rounded-xl border border-[#57585C]" key={index}>
              <h2>
                <button
                  className=" flex justify-between w-full items-center rounded-xl border-0 border-[#57585C]  px-2 py-5   "
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex items-center gap-4 text-[#57585C] pl-4 text-center">
                    <span className="text-sm font-semibold">
                      Day {item.day}
                    </span>
                    <span className="font-bold text-lg">{item.title}</span>
                  </div>

                  <div>
                    <svg
                      className={`transform transition-transform duration-300 ${
                        activeIndex === index && "transform rotate-180"
                      }`}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </div>
                </button>
              </h2>
              {activeIndex === index && (
                <div
                  className="transform transition-transform duration-300 "
                  style={{
                    maxHeight:
                      activeIndex === index
                        ? `${item.description.length * 24}px`
                        : "0",
                  }}
                >
                  <p className="whitespace-pre-wrap px-4 pb-4 text-sm text-[#57585C]">
                    {parse(item.description)}  
                  </p>
                </div>
              )}
            </div>
          ))}
    </div>
  );
};

export default Itinerary;
