import React from "react";
import Slider from "react-slick";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const SupportedBy = () => {
  const { data: images } = useAxiosAuth(`Partner`);
  const filteredImages = images.filter((item) => item.published);
  const onlySupport = filteredImages.filter((item) => item.type === 1);

  const settingsSup = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1315,
        settingsSup: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settingsSup: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settingsSup: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // console.log(onlySupport);
  return (
    <div className="xl:w-[90vw] px-5 mx-auto py-10 bg-[#fff] z-10 relative">
      <div className="">
        <h1 className="text-3xl text-[#1D306D] pb-2">Supported by</h1>
        {/* <p className="text-[#8A94B2]">Supporting Us To Satisfy Your Needs !</p> */}
      </div>
      {/* <div className="px-10 py-3"> */}
      {onlySupport.length > 6 ? (
        // <div className="flex flex-row md:gap-10 gap-3 z-[1] relative">
        <Slider {...settingsSup}>
          {onlySupport.map((image, index) => (
            <img
              key={index}
              className="md:w-[12vw] w-[16vw] h-[10vh] object-contain px-5 pt-3"
              src={image.image && `${BASE_URL}/${image.image.filePath}`}
              alt={image.image ? image.image.altInfo : "Image unavailable"}
            />
          ))}
        </Slider>
      ) : (
        // </div>
        <div className="lg:flex grid md:grid-cols-4 grid-cols-2 justify-items-center lg:gap-16 gap-5 justify-center">
          {onlySupport.map((image, index) => (
            <div className="flex-auto">
              <img
                key={index}
                className="md:w-[120px] w-[100px] md:max-h-[26vh] max-h-[20vh] object-contain z-10 relative mx-auto"
                src={image.image && `${BASE_URL}/${image.image.filePath}`}
                alt={image.image ? image.image.altInfo : "Image unavailable"}
              />
            </div>
          ))}
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default SupportedBy;
