import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { BASE_URL } from "../../utils/axios/axios";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[28vh] right-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[28vh] left-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}
const CarouselOverlay = ({ images, isOpen, onClose }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    isOpen && (
      <div className="carousel-overlay">
        <button className="close-button mr-4 mt-4" onClick={onClose}>
          <RxCross1 />
        </button>
        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  className="text-white text-center"
                  src={`${BASE_URL}/${image.filePath}`}
                  alt={image.altInfo}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  );
};

export default CarouselOverlay;
