import React, { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import CarouselOverlay from "../../components/general/carouselOverlay";
import Loader from "../../components/general/loader";
import PackageNav from "../../components/packages/packageNav";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import NotFound from "../notFound";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[12vh] right-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute top-[12vh] left-[-3vw] z-[1] text-4xl text-white rounded-lg cursor-pointer bg-secondary`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}
const GroupPackage = () => {
  const { packageSlug } = useParams();
  // const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const { data: groupPackage, error } = useAxiosAuth(
    `GroupDeparture/${packageSlug}`
  );

  console.log(groupPackage.package);

  // const openOverlay = () => {
  //   setIsOverlayOpen(true);
  // };

  // const closeOverlay = () => {
  //   setIsOverlayOpen(false);
  // };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="min-h-[42.4vh]">
      {error.length !== 0 ? (
        <NotFound />
      ) : groupPackage.package ? (
        <>
          <div className="xl:w-[90vw] px-5 mx-auto pt-16">
            <div className="md:grid md:grid-cols-3 md:max-h-[60vh] xl:gap-8 gap-5 md:space-y-0 space-y-6 content-center hidden">
              <div className="col-span-2">
                <img
                  className="w-full xl:h-[61.5vh] md:h-[60vh] h-[40vh] object-cover rounded-xl"
                  src={
                    groupPackage.package && groupPackage.package?.image1
                      ? `${BASE_URL}/${groupPackage.package.image1.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
              </div>

              <div className="md:flex md:flex-col xl:gap-8 gap-5">
                <img
                  className="h-fit md:max-h-[29vh] md:h-[29vh] object-cover rounded-xl"
                  src={
                    groupPackage.package && groupPackage.package?.image2
                      ? `${BASE_URL}/${groupPackage.package.image2.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
                <img
                  className="h-fit md:max-h-[29vh] md:h-[29vh] object-cover rounded-xl"
                  src={
                    groupPackage.package && groupPackage.package?.image3
                      ? `${BASE_URL}/${groupPackage.package.image3.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
              </div>
            </div>

            {/* mobile view carousel */}
            <div className="md:hidden">
              <Slider {...settings}>
                <img
                  className="h-[28vh] w-full rounded-xl object-cover"
                  src={
                    groupPackage.package && groupPackage.package?.image1
                      ? `${BASE_URL}/${groupPackage.package.image1.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
                <img
                  className="h-[28vh] w-full rounded-xl object-cover"
                  src={
                    groupPackage.package && groupPackage.package?.image2
                      ? `${BASE_URL}/${groupPackage.package.image2.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
                <img
                  className="h-[28vh] w-full rounded-xl object-cover"
                  src={
                    groupPackage.package && groupPackage.package?.image3
                      ? `${BASE_URL}/${groupPackage.package.image3.filePath}`
                      : "https://placehold.co/600x400.png"
                  }
                  alt="package"
                />
              </Slider>
            </div>
          </div>
          {/* <CarouselOverlay
            images={groupPackage.package.images}
            isOpen={isOverlayOpen}
            onClose={closeOverlay}
          /> */}

          <PackageNav
            packageInformation={groupPackage.package}
            group={groupPackage}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GroupPackage;
