import React from "react";
import Slider from "react-slick";

import parntersSvg from "../../assets/images/partnersSvg.svg";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const Partners = () => {
  const { data: images } = useAxiosAuth(`Partner`);
  const filteredImages = images.filter((item) => item.published);
  const onlyPartners = filteredImages.filter((item) => item.type === 2);
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1315,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // console.log(onlyPartners);

  return (
    <div className="xl:w-[90vw] px-5 mx-auto py-10 z-10">
      <div className="">
        <h1 className="text-3xl text-[#1D306D] pb-2">Key Stakeholders</h1>
        {/* <p className="text-[#8A94B2]">
          Partnering with top travel experts to create unforgettable experiences
          worldwide.
        </p> */}
      </div>
      <img
        className="absolute left-0 z-0 object-cover w-full md:h-[40vh] h-[30vh]"
        src={parntersSvg}
        alt="bg design"
      />
      {/* <div className="px-10 py-3"> */}
      {onlyPartners.length > 5 ? (
        // <div className="flex flex-row md:gap-10 gap-3 z-[1] relative">
        <Slider {...settings}>
          {onlyPartners.map((image, index) => (
            <img
              key={index}
              className="md:w-[12vw] w-[16vw] h-[10vh] object-contain pt-3 px-5"
              src={image.image && `${BASE_URL}/${image.image.filePath}`}
              alt={image.image ? image.image.altInfo : "Image unavailable"}
            />
          ))}
        </Slider>
      ) : (
        // </div>
        <div className="lg:flex grid md:grid-cols-4 grid-cols-2 justify-items-center lg:gap-16 gap-5 justify-center">
          {onlyPartners.map((image, index) => (
            <div className="flex-auto">
              <img
                key={index}
                className="md:w-[120px] w-[100px] md:max-h-[26vh] max-h-[20vh] object-contain z-10 relative mx-auto"
                src={image.image && `${BASE_URL}/${image.image.filePath}`}
                alt={image.image ? image.image.altInfo : "Image unavailable"}
              />
            </div>
          ))}
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default Partners;
