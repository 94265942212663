import React from "react";
import { Helmet } from "react-helmet";

import contactSvg from "../../assets/images/contactSvg.svg";
import ContactCarousel from "./contactCarousel";
import Destinations from "./destinations";

// import Excellence from "./excellence";

const Contact = () => {
  return (
    <div className=" pb-20">
      <Helmet>
        <title>Contact our Team</title>
        <meta
          name="description"
          content="Reach out to Druk Air Holidays for inquiries, support, or custom travel plans. We're here to assist with any questions or bookings for your perfect trip to Bhutan."
        />
        <meta
          name="keywords"
          content="Contact Druk Air Holidays, travel inquiries, Bhutan trip support, travel assistance Bhutan, Druk Air Holidays contact"
        />
      </Helmet>
      <div className="h-[80vh] bg-cover">
        <img
          src={require("../../assets/images/contact.jpg")}
          alt=""
          className="absolute top-0 h-[80vh] w-full object-cover"
        />
        <img
          className="left-0 bottom-[20vh] absolute w-screen"
          src={contactSvg}
          alt="/"
        />
      </div>
      {/* <div className="pb-16">
        <Excellence />
      </div> */}
      <ContactCarousel />
      <Destinations />
    </div>
  );
};

export default Contact;
