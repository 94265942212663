import React from "react";

const IncludesExcludes = ({ packageInformation }) => {
  return (
    <div className="block md:w-[70vw] w-[90vw] md:mt-0 mt-8 p-6 border bg-[#FFFFFF] rounded-lg  md:mb-0 mb-8">
      <h5 className="mb-2 ">Inclusions</h5>
      <div
        className="list-inside text-sm text-[#8A94B2] pb-8"
        dangerouslySetInnerHTML={{
          __html: packageInformation.includes,
        }}
      ></div>
      <h5 className="mb-2 pt-5 border-t border-gray-300">Exclusions</h5>
      <div
        className="list-inside text-sm text-[#8A94B2] pb-10"
        dangerouslySetInnerHTML={{
          __html: packageInformation.excludes,
        }}
      ></div>
    </div>
  );
};

export default IncludesExcludes;
