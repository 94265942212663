import parse from "html-react-parser";
import React from "react";
import { FaRegUser } from "react-icons/fa";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const TravelPower = () => {
  // const handleClick = (event) => {
  //   event.preventDefault();
  //   const targetId = event.currentTarget.getAttribute("href").substring(1);
  //   const targetSection = document.getElementById(targetId);

  //   window.scrollTo({
  //     top: targetSection.offsetTop,
  //     behavior: "smooth",
  //   });
  // };

  const { data: blogs } = useAxiosAuth(`blogpost`);

  return (
    <>
      {blogs.slice(0, 1).map((blog, index) => (
        <div
          id="travelPower"
          className="xl:w-[90vw] px-5 mx-auto flex flex-col md:flex-row mt-10 lg:my-10 py-10"
        >
          <img
            src={`${BASE_URL}/${blog.thumbnail.filePath}`}
            alt="power of travel"
            className="rounded-3xl max-h-[50vh] w-full xl:w-[130rem] lg:w-[20rem] md:w-[15rem] object-cover"
          />
          <div className="flex flex-col md:mx-8 mt-8 md:my-auto">
            <div key={index}>
              <h1 className="text-3xl md:text-4xl lg:text-6xl text-[#4B4F52]">
                {blog.title}
              </h1>
              <span className="block mt-4 text-secondary">
                <FaRegUser className="text-secondary inline" /> {blog.author}
              </span>
              <div className="w-full my-4 text-[14px] md:text-[16px] lg:text-[18px] text-[#959595] text-justify">
                {parse(blog.content.substring(0, 600))}
              </div>
              <a
                href={`/sustainability/${blog.id}`}
                className="inline-block bg-secondary text-white text-center rounded-full py-2 md:py-3 lg:py-4 px-4 mb-4"
                // onClick={handleClick}
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TravelPower;
