import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Slider from "react-slick";

import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] right-8 border-2 p-2 bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] left-8 border-2 p-2  bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}
const Banner = () => {
  const { data: banner } = useAxiosAuth(`Banner`);
  const filteredBanners = banner.filter((item) => item.published);
  console.log(filteredBanners);
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="py-3">
      {filteredBanners.length > 1 ? (
        <Slider {...settings}>
          {filteredBanners.map((image, index) => (
            <img
              key={index}
              className="w-screen h-full object-contain pt-3"
              src={image.image && `${BASE_URL}/${image.image.filePath}`}
              alt={image.image ? image.image.altInfo : "Image unavailable"}
            />
          ))}
        </Slider>
      ) : (
        <div>
          {filteredBanners.map((image, index) => (
            <img
              key={index}
              className="w-screen object-contain z-10 relative mx-auto"
              src={image.image && `${BASE_URL}/${image.image.filePath}`}
              alt={image.image ? image.image.altInfo : "Image unavailable"}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Banner;
